<template>
    <div id="app">
        <router-view></router-view>
        <LoginDialog></LoginDialog>
    </div>
</template>

<script>
import { getUserInfo } from '@/api/user'
import { getToken } from '@/utils/storage'
import Cookies from "js-cookie";
import LoginDialog from '@/views/LoginDialog.vue';
export default {
  created () {
    const token = getToken();
    // this.$toast.success(token + 'token')
    const tokencookie = Cookies.get("token");
    // this.$toast.success(tokencookie + 'tokencookie')
    if (token || tokencookie) {
      this.getUserInfo()
      this.$toast.success('欢迎回来，已登录')
    }
  },
  components: { LoginDialog },
  methods: {
    // 获取用户信息
    async getUserInfo () {
      try {
        const res = await getUserInfo()
        if (res.code === 200) {
          this.$store.commit('user', res)
          if (this.$route.path === '/app/APP_User') { this.$EventBus.$emit('getMessageSums', '获取会员未读消息') }
        } else {
          console.log(res.msg)
        }
      } catch (err) {
        console.log(err)
      }
    },
  }
}
</script>

<style lang="less">
#app {
    background-color: #f6f6f6;
}
</style>
