<template>
    <div>
        <van-dialog v-if="numberIP" v-model="ForgetPasswordDialog" title="忘记密码?" :showConfirmButton="false" :showCancelButton="false" get-container="#app">
            <van-icon name="cross" @click="ForgetPasswordDialogGB()" class="cross" />
            <van-form @failed="onFailed" :show-error-message="false" class="formclass" ref="form">
                <van-field
                    v-model="phonenumber"
                    name="phonenumber"
                    placeholder="手机号"
                    :rules="[
                        { required: true, message: '请输入手机号' },
                        { pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号' }
                    ]"
                />
                <div class="flex yzmform">
                    <van-field
                        class="yzm"
                        v-model="code"
                        name="code"
                        placeholder="短信验证码"
                        :rules="[
                            { required: true, message: '请输入短信验证码' },
                            { pattern: /^[0-9]\d{5}$/, message: '验证码长度必须是6位的数字!' }
                        ]"
                    />
                    <van-button native-type="button" class="getyzm" :disabled="disabled" @click="getCaptchas()">
                        <span v-if="isCountDownShows">获取验证码</span>
                        <van-count-down v-if="isCountDownShow" :time="time" format="ss s" />
                    </van-button>
                </div>
                <van-field
                    v-model="password"
                    name="password"
                    type="password"
                    placeholder="密码"
                    :rules="[
                        { required: true, message: '请输入密码' },
                        { min: 6, max: 18, message: '密码必须在6-18位之间', trigger: 'blur' }
                    ]"
                />
                <van-field
                    v-model="checkPass"
                    name="checkPass"
                    type="password"
                    placeholder="确认密码"
                    :rules="[
                        { required: true, message: '请输入密码' },
                        { min: 6, max: 18, message: '密码必须在6-18位之间', trigger: 'blur' }
                    ]"
                />
                <div class="buttonVan">
                    <van-button :loading="loading" loading-text="进行中..." native-type="submit" class="btn" @click="onFailed()">确定</van-button>
                </div>
                <div class="forget" @click="openLoginDialog()">登录</div>
            </van-form>
        </van-dialog>
        <van-dialog v-else v-model="ForgetPasswordDialog" title="忘记密码?" :showConfirmButton="false" :showCancelButton="false" get-container="#app">
            <van-icon name="cross" @click="ForgetPasswordDialogGB()" class="cross" />
            <van-form @failed="onFailed" :show-error-message="false" class="formclass" ref="form">
                <van-field
                    v-model="phonenumber"
                    name="phonenumber"
                    placeholder="手机号"
                    :rules="[
                        { required: true, message: '请输入手机号' },
                        { pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号' }
                    ]"
                />
                <div class="flex yzmform">
                    <van-field
                        class="yzm"
                        v-model="yzmImg"
                        name="yzmImg"
                        placeholder="图片验证码"
                        :rules="[
                            { required: true, message: '请输入验证码' },
                            { pattern: /^[0-9]\d{3}$/, message: '验证码长度必须是4位的数字!' }
                        ]"
                    />
                    <van-button native-type="button" class="getyzms" :disabled="disabled" @click="captchaImage()">
                        <img :src="this.yzmUrl ? this.yzmUrl : ''" alt="" />
                    </van-button>
                </div>
                <div class="flex yzmform">
                    <van-field
                        class="yzm"
                        v-model="code"
                        name="code"
                        placeholder="短信验证码"
                        :rules="[
                            { required: true, message: '请输入短信验证码' },
                            { pattern: /^[0-9]\d{5}$/, message: '验证码长度必须是6位的数字!' }
                        ]"
                    />
                    <van-button native-type="button" class="getyzm" :disabled="disabled" @click="getCaptchasS()">
                        <span v-if="isCountDownShows">获取验证码</span>
                        <van-count-down v-if="isCountDownShow" :time="time" format="ss s" />
                    </van-button>
                </div>
                <van-field
                    v-model="password"
                    name="password"
                    type="password"
                    placeholder="密码"
                    :rules="[
                        { required: true, message: '请输入密码' },
                        { min: 6, max: 18, message: '密码必须在6-18位之间', trigger: 'blur' }
                    ]"
                />
                <van-field
                    v-model="checkPass"
                    name="checkPass"
                    type="password"
                    placeholder="确认密码"
                    :rules="[
                        { required: true, message: '请输入密码' },
                        { min: 6, max: 18, message: '密码必须在6-18位之间', trigger: 'blur' }
                    ]"
                />
                <div class="buttonVan">
                    <van-button :loading="loading" loading-text="进行中..." native-type="submit" class="btn" @click="onFailed()">确定</van-button>
                </div>
                <div class="forget" @click="openLoginDialog()">登录</div>
            </van-form>
        </van-dialog>
    </div>
</template>

<script>
import { getCaptchas, phoneUpdatepasswords, phoneVerifys, getIPs, captchaImage, getPhoneCaptchas } from '@/api/game'
import { delToken } from '@/utils/storage'
export default {
  props: {},
  created () {
    // this.getIPsS()
    // this.captchaImage()
  },
  data () {
    return {
      numberIP: true,//判断IP是否使用
      ForgetPasswordDialog: false,
      loading: false,
      phonenumber: '',
      password: '',
      checkPass: '',
      code: '',
      disabled: false,
      time: 60 * 1000, //倒计时
      isCountDownShow: false, // 默认不显示倒计时
      isCountDownShows: true, // 显示获取验证码文字
      sMscode: '',//设置密码sm码
      yzmUrl: '',//图片验证码图片
      uuid: '',//图片验证码uuid
      yzmImg: '',//图片验证码
    }
  },
  mounted () { },
  methods: {
    async onFailed (errorInfo) {
      if (this.password === this.checkPass) {
        this.$refs.form.validate().then(() => {
          //成功时的程序
          this.loading = true
          try {
            this.phoneVerifys().then(() => {
              phoneUpdatepasswords({ phonenumber: this.phonenumber, password: this.password, sMscode: this.code }).then(res => {
                if (res.code === 200) {
                  // this.$message({ message: '恭喜你，密码设置成功，请重新登陆', type: 'success', center: true });
                  this.$toast.success('恭喜你，密码设置成功，请重新登陆!')
                  this.ForgetPasswordDialog = false;
                  this.$store.commit('user', {}) // 清空vuex数据
                  delToken()// 把本地的token清空
                  this.phonenumber = ''; // 清空手机号
                  this.code = ''; // 清空验证码
                  this.password = ''; // 清空密码
                  this.checkPass = ''; // 清空密码
                  this.yzmImg = '';
                  this.isCountDownShow = false; // 隐藏倒计时
                  this.isCountDownShows = true; // 显示获取验证码文字
                  this.btnisShow = false; // 打开禁用发送验证码按钮
                  this.loading = false; // 关闭禁用发送验证码按钮
                  this.disabled = false;
                } else {
                  this.$toast.fail(res.msg)
                }
              });
            })
          } catch (error) {
            console.log(error)
          }
        }).catch(() => {
          //失败时的程序
          if (errorInfo === undefined) {
          } else {
            this.$toast(errorInfo.errors[0].message)
          }
        })
      } else {
        this.$toast('两次输入密码不一致!')
      }
    },
    // 直接获取手机验证码
    async getCaptchas (formName) {
      this.$refs.form.validate('phonenumber').then(async () => {
        try {
          const res = await getCaptchas({ phonenumber: this.phonenumber });
          if (res.code === 200) {
            this.$toast.success(res.msg)
            this.isCountDownShow = true; // 显示倒计时
            this.isCountDownShows = false; // 显示文字
            this.disabled = true; // 打开禁用发送验证码按钮
            setTimeout(() => {
                this.isCountDownShow = false; // 显示倒计时
                this.isCountDownShows = true; // 显示文字
                this.disabled = false; // 关闭禁用发送验证码按钮
              }, 60000)
          } else {
            this.$toast.fail(res.msg)
          }
        } catch (err) {
          console.log(err);
        }
      }).catch((error) => {
        this.$toast(error.message)
      })
    },
    //增加图片验证码获取手机验证码
    async getCaptchasS () {
      this.$refs.form.validate('yzmImg').then(async () => {
        try {
          getPhoneCaptchas({ phonenumber: this.phonenumber, code: this.yzmImg, uuid: this.uuid }).then(res => {
            if (res.code === 200) {
              this.$toast.success(res.msg)
              this.isCountDownShow = true; // 显示倒计时
              this.isCountDownShows = false; // 显示文字
              this.disabled = true; // 打开禁用发送验证码按钮
              setTimeout(() => {
                this.isCountDownShow = false; // 显示倒计时
                this.isCountDownShows = true; // 显示文字
                this.disabled = false; // 关闭禁用发送验证码按钮
              }, 60000)
            } else {
              this.$toast.fail(res.msg)
            }
          });
        } catch (err) {
          console.log(err);
        }
      }).catch((error) => {
        this.$toast(error.message)
      })
    },
    // 验证手机验证码
    async phoneVerifys (formName) {
      try {
        const res = await phoneVerifys({ phonenumber: this.phonenumber, code: this.code })
        if (res.code === 200) {
          this.$toast.success(res.msg)
        } else {
          this.$toast.fail(res.msg)
          this.ForgetPasswordDialog = false
          this.isCountDownShows = true
          this.isCountDownShow = false;
          this.disabled = false;
          this.loading = false;
          this.phonenumber = ''
          this.password = ''
          this.checkPass = ''
          this.code = ''
          this.yzmImg = ''
          //清空校验
          this.$refs.form.resetValidation()
        }
      } catch (err) {
        console.log(err);
      }
    },
    openLoginDialog () {
      this.ForgetPasswordDialog = false;
      this.$EventBus.$emit('openLoginDialog', '打开登录弹窗')
    },
    //关闭忘记密码
    ForgetPasswordDialogGB () {
      this.ForgetPasswordDialog = false
      this.isCountDownShows = true
      this.isCountDownShow = false;
      this.disabled = false;
      this.loading = false;
      this.phonenumber = ''
      this.password = ''
      this.checkPass = ''
      this.code = ''
      this.yzmImg = ''
      //清空校验
      this.$refs.form.resetValidation()
    },
    // 获取IP使用情况
    async getIPsS () {
      const res = await getIPs()
      if (res.code === 200) {
        this.numberIP = res.status
      }
    },
    //获取图片验证码
    async captchaImage () {
      const res = await captchaImage();
      if (res.code === 200) {
        this.yzmUrl = 'data:image/gif;base64,' + res.img;
        this.uuid = res.uuid;
      }
    },
  },
  computed: {},
  watch: {},
  components: {},
}
</script>

<style scoped lang='less'>
.van-cell {
    width: 300px;
    height: 36px;
    line-height: 36px;
    background: #f6f6f6;
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 0;
    padding-left: 16px;
}
.formclass {
    margin-top: 32px;
}
.cross {
    position: absolute;
    top: 10px;
    left: 324px;
    z-index: 99;
    font-size: 20px;
}
.buttonVan {
    text-align: center;
    padding-top: 15px;
    margin-bottom: 20px;
}
.forget {
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    color: #737373;
    text-align: center;
    margin-bottom: 18px;
}
.yzmform {
    width: 300px;
    margin: 0 auto;
    .yzm {
        width: 188px;
        margin-right: 7px;
    }
    .getyzm {
        width: 105px;
        height: 36px;
        background: #ffe5c2;
        border-radius: 8px;
    }
    .getyzms {
        width: 105px;
        height: 36px;
        img {
            width: 105px;
            height: 36px;
        }
    }
}
</style>
