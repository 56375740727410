// 封装文章列表的接口
import request from '@/utils/request'

// 背景
export const bgs = () => { return request({ method: 'GET', url: '/website/carousel/bg/list' }) }
// 分类筛选所有游戏列表
export const filter_lists = value => { return request({ method: 'GET', url: '/website/game/filter/list', params: value }) }
// 会员登录选项开关
export const Memberloginoptions = () => { return request({ method: 'GET', url: '/external/member/loginInfo/option' }) }
// 新服列表
export const newest_lists = value => { return request({ method: 'GET', url: '/website/game/newest/list', params: { pageNum: value.pageNum, pageSize: value.pageSize } }) }
// 查询游戏详情
export const gameDetails = (params) => { return request({ method: 'GET', url: '/website/game' + '/' + params }) }
//查询游戏收藏数量
export const gamecollections = (params) => { return request({ method: 'GET', url: '/version/collect' + '/' + params }) }
// 查询网站信息
export const QueryWebsiteInformations = () => { return request({ method: 'GET', url: '/system/user/setting/website' }) }
// 直接获取手机验证码
export const getCaptchas = (data) => { return request({ method: 'POST', url: '/phone/captcha', data }) }
// 实名认证查询
export const selectIdCardverifyByUserIds = () => { return request({ method: 'POST', url: '/member/idCardAudit/selectIdCardverifyByUserId' }) }
// 查询会员未读消息
export const getMessageSums = () => { return request({ method: 'GET', url: '/system/message/messageSum' }) }
// 忘记密码手机号修改密码
export const phoneUpdatepasswords = (data) => { return request({ method: 'PUT', url: '/system/user/updatepassword', data }) }
// 手机号验证码验证
export const phoneVerifys = (data) => { return request({ method: 'POST', url: '/phone/captcha/verify', data }) }
// 获取IP使用情况
export const getIPs = () => { return request({ method: 'GET', url: '/check/ip/first' }) }
// 验证码
export const captchaImage = () => { return request({ method: 'GET', url: '/captchaImage' }) }
// 获取手机验证码(绑定)
export const getPhoneCaptchas = value => { return request({ method: 'POST', url: '/getPhoneCaptcha', data: value }) }
// 一键已读
export const Messagereads = () => { return request({ method: 'PUT', url: '/system/message/read' }) }
// 查询系统消息
export const system_messages = value => { return request({ method: 'GET', url: '/system/message/list', params: value }) }
// 查询游戏消息
export const game_messages = value => { return request({ method: 'GET', url: '/system/message/game/list', params: value }) }
// 修改删除我的消息标识
export const dateMemDels = (data) => { return request({ method: 'PUT', url: '/system/message/updateMemDel', data }) }
// 查询游戏收藏列表
export const my_lists = value => { return request({ method: 'GET', url: '/version/collect/my/list', params: value }) }
// 游戏收藏
export const postGameCollects = (data) => { return request({ method: 'POST', url: '/version/collect', data }) }
// 取消游戏收藏
export const Collections = value => { return request({ method: 'POST', url: '/version/collect', data: value }) }
// 游戏分类
export const category_lists = value => { return request({ method: 'GET', url: '/website/game/category/list', params: { pageNum: value.pageNum, pageSize: value.pageSize } }) }
// 搜索热门推荐
export const hot_lists = value => { return request({ method: 'GET', url: '/website/game/exchange/message', params: { pageNum: value.pageNum, pageSize: value.pageSize } }) }
// 实名认证
export const checknows = value => { return request({ method: 'POST', url: '/member/idCardAudit/checknow', data: value }) }
// 查询用户签到天数
export const selectSginin = value => { return request({ method: 'GET', url: '/selectSginin' }) }
//获取签到数据
export const SignS = value => { return request({ method: 'GET', url: '/phone/wechat/sign' }) }
//轮播图
export const carousel = value => { return request({ method: 'GET', url: '/website/carousel/list' }) }
// 获取成长福利文字
export const memUserGrowths = () => { return request({ method: 'GET', url: '/memUser/growth' }) }
// 获取成长福利数据
export const memUserLevels = () => { return request({ method: 'GET', url: '/memUser/level' }) }
//获取网站主题，账号回收开关，新手指南开关，在线客服开关，加入我们开关的综合接口
export const integratePorts = () => { return request({ method: 'GET', url: '/external/home/page/integrate' }) }
// 获取支付产品、充值赠送比例
export const ratios = (value) => { return request({ method: 'GET', url: '/payment/product/list', params: value }) }
// 查询充值金额
export const GetTopUpAmounts = () => { return request({ method: 'GET', url: '/system/user/setting/topUp' }) }
// 获取充值提示的文本
export const prompts = () => { return request({ method: 'GET', url: '/system/user/setting/prompt' }) }
// 获取充值二维码
export const PayQrcodes = (data) => { return request({ method: 'POST', url: '/pay/sm/qrcode', data }) }
// 获取支付状态（轮询）
export const paystatuss = (data) => { return request({ method: 'POST', url: '/pay/sm/status', data }) }
// 用户充值订单查询（分页）
export const payOrders = (value) => { return request({ method: 'GET', url: '/order/pay/time/list', params: value }) }
// 绑定手机号
export const boundPhoneSs = (data) => { return request({ method: 'PUT', url: '/system/user/boundPhones', data }) }
//获取网站倍率
export const magnifications = () => { return request({ method: 'GET', url: '/external/home/page/member/ratio' }) }
