<template>
    <div class="layout-page">
        <router-view></router-view>
        <van-tabbar route active-color="#292929" inactive-color="#a5a5a5">
            <van-tabbar-item to="/app/APP_Game">
                <span>游戏</span>
                <template #icon="props">
                    <img :src="props.active ? icon.active : icon.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/app/APP_PayMent">
                <span>充值</span>
                <template #icon="props">
                    <img :src="props.active ? icon.active_1 : icon.inactive_1" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/app/APP_User">
                <span>我的</span>
                <template #icon="props">
                    <img :src="props.active ? icon.active_2 : icon.inactive_2" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
export default {
  name: 'layout-page',
  data () {
    return {
      active: 0,
      icon: {
        active: require('@/assets/game-1.png'),
        inactive: require('@/assets/game.png'),
        active_1: require('@/assets/topup-1.png'),
        inactive_1: require('@/assets/topup.png'),
        active_2: require('@/assets/user-1.png'),
        inactive_2: require('@/assets/user.png'),
      },
    };
  },
}

</script>

<style lang="less" scoped>
.van-tabbar {
    height: 56px;
}
img {
    width: 28px;
    height: 28px !important;
}
</style>
