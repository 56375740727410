var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-page"},[_c('van-dialog',{attrs:{"title":"登录","showConfirmButton":false,"showCancelButton":false,"get-container":"#app"},model:{value:(_vm.loginDialog),callback:function ($$v) {_vm.loginDialog=$$v},expression:"loginDialog"}},[_c('van-icon',{staticClass:"cross",attrs:{"name":"cross"},on:{"click":function($event){return _vm.loginDialogGB()}}}),_c('van-form',{ref:"form",staticClass:"formclass",attrs:{"show-error-message":false},on:{"failed":_vm.onFailed}},[_c('van-field',{attrs:{"name":"phoneNumber","placeholder":"手机号","rules":[
                    { required: true, message: '请输入用户名' },
                    { pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号' }
                ]},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('van-field',{attrs:{"type":"password","name":"password","placeholder":"密码","rules":[
                    { required: true, message: '请输入密码' },
                    { pattern: /^[^\u4e00-\u9fa5 ]{6,}$/, message: '密码不少于6位' }
                ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{class:_vm.ishowwx === true ? 'buttonVan' : 'buttonVanishow'},[_c('van-button',{staticClass:"btn",attrs:{"loading":_vm.loading,"loading-text":"登录中...","native-type":"submit"},on:{"click":function($event){return _vm.onFailed()}}},[_vm._v("登录")])],1),(_vm.ishowwx)?_c('div',{staticClass:"icons",on:{"click":function($event){return _vm.wechatLogin()}}},[_c('svg-icon',{attrs:{"icon-file-name":"微信"}})],1):_vm._e(),_c('div',{staticClass:"forget",on:{"click":function($event){return _vm.openPassword()}}},[_vm._v("忘记密码？")])],1)],1),_c('ForgetPasswordDialog',{ref:"ForgetPasswordDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }