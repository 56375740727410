<template>
    <div class="login-page">
        <van-dialog v-model="loginDialog" title="登录" :showConfirmButton="false" :showCancelButton="false" get-container="#app">
            <van-icon name="cross" @click="loginDialogGB()" class="cross" />
            <van-form @failed="onFailed" :show-error-message="false" class="formclass" ref="form">
                <van-field
                    v-model="phoneNumber"
                    name="phoneNumber"
                    placeholder="手机号"
                    :rules="[
                        { required: true, message: '请输入用户名' },
                        { pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号' }
                    ]"
                />
                <van-field
                    v-model="password"
                    type="password"
                    name="password"
                    placeholder="密码"
                    :rules="[
                        { required: true, message: '请输入密码' },
                        { pattern: /^[^\u4e00-\u9fa5 ]{6,}$/, message: '密码不少于6位' }
                    ]"
                />
                <div :class="ishowwx === true ? 'buttonVan' : 'buttonVanishow'">
                    <van-button :loading="loading" loading-text="登录中..." native-type="submit" class="btn" @click="onFailed()">登录</van-button>
                </div>
                <div class="icons" @click="wechatLogin()" v-if="ishowwx">
                    <svg-icon icon-file-name="微信" />
                </div>
                <div class="forget" @click="openPassword()">忘记密码？</div>
            </van-form>
        </van-dialog>
        <ForgetPasswordDialog ref="ForgetPasswordDialog"></ForgetPasswordDialog>
    </div>
</template>

<script>
window.isInWeixinApp = function () {
  return /MicroMessenger/.test(navigator.userAgent);
};
import Cookies from "js-cookie";
import { login, getUserInfo, wechatLogin } from '@/api/user'
import { setToken } from '@/utils/storage'
import ForgetPasswordDialog from '@/components/ForgetPasswordDialog.vue'
export default {
  components: { ForgetPasswordDialog },
  name: 'LoginDialog',
  created () {
    this.getIsWxClient()
  },
  data () {
    return {
      loginDialog: false,
      loading: false,
      phoneNumber: '',
      password: '',
      ishowwx: true
    }
  },
  mounted () {
    this.$EventBus.$on('openLoginDialog', data => { this.loginDialog = true })
    this.$EventBus.$on('getUserInfo', data => { this.getUserInfo() })
  },
  methods: {
    getIsWxClient () {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // this.$toast.success('微信游览器')
      } else {
        this.ishowwx = false
        // this.$toast.success('不是微信游览器')
      }
    },
    async wechatLogin () {
      // window.location.href = "/api/wechat/authorization/login"
      try {
        const res = await wechatLogin()
        // console.log(res);
        if (res.code === 200) {
          window.location.href = res.data
        } else {
          console.log(res.msg)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async onFailed (errorInfo) {
      this.$refs.form.validate().then(() => {
        //成功时的程序
        this.loading = true
        try {
          login({ phoneNumber: this.phoneNumber, password: this.password, phoneSign: "is_phone" }).then((res) => {
            console.log(this.phoneNumber);
            console.log(this.password);
            if (res.code === 200 && res.error === 0) {
              setToken(res.token)
              Cookies.set("token", res.token);
              this.$toast.success('登录成功')
              this.loading = false
              this.loginDialog = false
              this.getUserInfo()//获取用户信息
            } else {
              this.$toast.fail(res.msg)
              this.loading = false
            }
          })
        } catch (error) {
          console.log(error)
        }
      }).catch(() => {
        //失败时的程序
        if (errorInfo === undefined) {
        } else {
          this.$toast(errorInfo.errors[0].message)
        }
      })
    },
    // 获取用户信息
    async getUserInfo () {
      try {
        const res = await getUserInfo()
        if (res.code === 200) {
          // console.log(res)
          this.$store.commit('user', res)
          if (this.$route.path === '/app/APP_User') { this.$EventBus.$emit('getMessageSums', '获取会员未读消息') }
        } else {
          console.log(res.msg)
        }
      } catch (err) {
        console.log(err)
      }
    },
    //关闭弹窗
    loginDialogGB () {
      this.loginDialog = false
      this.phoneNumber = ''
      this.password = ''
      //清空校验
      this.$refs.form.resetValidation()
    },
    openPassword () {
      this.loginDialog = false
      this.$refs.ForgetPasswordDialog.ForgetPasswordDialog = true
      this.$refs.ForgetPasswordDialog.getIPsS()
      this.$refs.ForgetPasswordDialog.captchaImage()
    }
  },
  beforeDestroy () {//移除监听事件
    this.$EventBus.$off("openLoginDialog")
    this.$EventBus.$off("getUserInfo")
  },
}
</script>

<style lang="less" scoped>
.van-cell {
    width: 300px;
    height: 36px;
    line-height: 36px;
    background: #f6f6f6;
    border-radius: 8px;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 0;
    padding-left: 16px;
}
.formclass {
    margin-top: 32px;
}
.cross {
    position: absolute;
    top: 10px;
    left: 324px;
    z-index: 99;
    font-size: 20px;
}
.buttonVan {
    text-align: center;
    padding-top: 15px;
}
.buttonVanishow {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 40px;
}
.forget {
    font-size: 14px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    color: #737373;
    text-align: center;
    margin-bottom: 18px;
}
.icons {
    font-size: 30px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>
