/* 封装axios用于发送请求 */
import store from '@/store'
import axios from 'axios'
import { Toast } from 'vant'
import { delToken, getToken } from './storage'
import router from '@/router'
import Cookies from "js-cookie";

// 创建一个新的axios实例
const request = axios.create({
  baseURL: '/api', // 请求基地址
  timeout: 10000 // 超时时间
})

// 发送请求前处理request的数据
axios.defaults.transformRequest = [function (data) {
  let newData = ''
  for (let k in data) {
    newData += encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&'
  }
  return newData
}]
// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const token = getToken() // 从后台得到token后存入本地，现在取token用
    if (token) {
      config.headers = {
        AuthorizationMEM: 'Bearer ' + token
      } // config就是请求对象，给请求头加上token
    }
    if (config.url === '/captchaImage') {
      config.headers = {}
    }

    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.data.code === 401) {
      Toast('登录状态已过期，请重新登录')
      store.commit('user', {}) // 清空vuex数据
      delToken()// 把本地的token清空
      Cookies.remove('token')
      router.push('/app/APP_User')// 三秒后回到首页
    }
    return response.data
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export default request
