// 封装登录注册的接口
import request from '@/utils/request'

// 登录请求函数
export const login = (data) => {
  return request({ method: 'POST', url: '/login', data })
}

// 获取用户信息
export const getUserInfo = () => {
  return request('/getInfo')
}

// 退出登录
export const logouts = (data) => {
  return request({ method: 'POST', url: '/logout', data })
}

// 微信授权登录
export const wechatLogin = () => {
  return request('/wechat/authorization/login')
}
