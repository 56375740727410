import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
  // 指定的存储是本地存储
  storage: window.localStorage
})
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_mes: {}, // 用户数据
  },
  getters: {},
  mutations: {
    user (state, user) {
      state.user_mes = user
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin]
})
