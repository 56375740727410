var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.numberIP)?_c('van-dialog',{attrs:{"title":"忘记密码?","showConfirmButton":false,"showCancelButton":false,"get-container":"#app"},model:{value:(_vm.ForgetPasswordDialog),callback:function ($$v) {_vm.ForgetPasswordDialog=$$v},expression:"ForgetPasswordDialog"}},[_c('van-icon',{staticClass:"cross",attrs:{"name":"cross"},on:{"click":function($event){return _vm.ForgetPasswordDialogGB()}}}),_c('van-form',{ref:"form",staticClass:"formclass",attrs:{"show-error-message":false},on:{"failed":_vm.onFailed}},[_c('van-field',{attrs:{"name":"phonenumber","placeholder":"手机号","rules":[
                    { required: true, message: '请输入手机号' },
                    { pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号' }
                ]},model:{value:(_vm.phonenumber),callback:function ($$v) {_vm.phonenumber=$$v},expression:"phonenumber"}}),_c('div',{staticClass:"flex yzmform"},[_c('van-field',{staticClass:"yzm",attrs:{"name":"code","placeholder":"短信验证码","rules":[
                        { required: true, message: '请输入短信验证码' },
                        { pattern: /^[0-9]\d{5}$/, message: '验证码长度必须是6位的数字!' }
                    ]},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('van-button',{staticClass:"getyzm",attrs:{"native-type":"button","disabled":_vm.disabled},on:{"click":function($event){return _vm.getCaptchas()}}},[(_vm.isCountDownShows)?_c('span',[_vm._v("获取验证码")]):_vm._e(),(_vm.isCountDownShow)?_c('van-count-down',{attrs:{"time":_vm.time,"format":"ss s"}}):_vm._e()],1)],1),_c('van-field',{attrs:{"name":"password","type":"password","placeholder":"密码","rules":[
                    { required: true, message: '请输入密码' },
                    { min: 6, max: 18, message: '密码必须在6-18位之间', trigger: 'blur' }
                ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"name":"checkPass","type":"password","placeholder":"确认密码","rules":[
                    { required: true, message: '请输入密码' },
                    { min: 6, max: 18, message: '密码必须在6-18位之间', trigger: 'blur' }
                ]},model:{value:(_vm.checkPass),callback:function ($$v) {_vm.checkPass=$$v},expression:"checkPass"}}),_c('div',{staticClass:"buttonVan"},[_c('van-button',{staticClass:"btn",attrs:{"loading":_vm.loading,"loading-text":"进行中...","native-type":"submit"},on:{"click":function($event){return _vm.onFailed()}}},[_vm._v("确定")])],1),_c('div',{staticClass:"forget",on:{"click":function($event){return _vm.openLoginDialog()}}},[_vm._v("登录")])],1)],1):_c('van-dialog',{attrs:{"title":"忘记密码?","showConfirmButton":false,"showCancelButton":false,"get-container":"#app"},model:{value:(_vm.ForgetPasswordDialog),callback:function ($$v) {_vm.ForgetPasswordDialog=$$v},expression:"ForgetPasswordDialog"}},[_c('van-icon',{staticClass:"cross",attrs:{"name":"cross"},on:{"click":function($event){return _vm.ForgetPasswordDialogGB()}}}),_c('van-form',{ref:"form",staticClass:"formclass",attrs:{"show-error-message":false},on:{"failed":_vm.onFailed}},[_c('van-field',{attrs:{"name":"phonenumber","placeholder":"手机号","rules":[
                    { required: true, message: '请输入手机号' },
                    { pattern: /^[1][3-9][0-9]{9}$/, message: '请输入正确的手机号' }
                ]},model:{value:(_vm.phonenumber),callback:function ($$v) {_vm.phonenumber=$$v},expression:"phonenumber"}}),_c('div',{staticClass:"flex yzmform"},[_c('van-field',{staticClass:"yzm",attrs:{"name":"yzmImg","placeholder":"图片验证码","rules":[
                        { required: true, message: '请输入验证码' },
                        { pattern: /^[0-9]\d{3}$/, message: '验证码长度必须是4位的数字!' }
                    ]},model:{value:(_vm.yzmImg),callback:function ($$v) {_vm.yzmImg=$$v},expression:"yzmImg"}}),_c('van-button',{staticClass:"getyzms",attrs:{"native-type":"button","disabled":_vm.disabled},on:{"click":function($event){return _vm.captchaImage()}}},[_c('img',{attrs:{"src":this.yzmUrl ? this.yzmUrl : '',"alt":""}})])],1),_c('div',{staticClass:"flex yzmform"},[_c('van-field',{staticClass:"yzm",attrs:{"name":"code","placeholder":"短信验证码","rules":[
                        { required: true, message: '请输入短信验证码' },
                        { pattern: /^[0-9]\d{5}$/, message: '验证码长度必须是6位的数字!' }
                    ]},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('van-button',{staticClass:"getyzm",attrs:{"native-type":"button","disabled":_vm.disabled},on:{"click":function($event){return _vm.getCaptchasS()}}},[(_vm.isCountDownShows)?_c('span',[_vm._v("获取验证码")]):_vm._e(),(_vm.isCountDownShow)?_c('van-count-down',{attrs:{"time":_vm.time,"format":"ss s"}}):_vm._e()],1)],1),_c('van-field',{attrs:{"name":"password","type":"password","placeholder":"密码","rules":[
                    { required: true, message: '请输入密码' },
                    { min: 6, max: 18, message: '密码必须在6-18位之间', trigger: 'blur' }
                ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"name":"checkPass","type":"password","placeholder":"确认密码","rules":[
                    { required: true, message: '请输入密码' },
                    { min: 6, max: 18, message: '密码必须在6-18位之间', trigger: 'blur' }
                ]},model:{value:(_vm.checkPass),callback:function ($$v) {_vm.checkPass=$$v},expression:"checkPass"}}),_c('div',{staticClass:"buttonVan"},[_c('van-button',{staticClass:"btn",attrs:{"loading":_vm.loading,"loading-text":"进行中...","native-type":"submit"},on:{"click":function($event){return _vm.onFailed()}}},[_vm._v("确定")])],1),_c('div',{staticClass:"forget",on:{"click":function($event){return _vm.openLoginDialog()}}},[_vm._v("登录")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }