import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import store from './store'
import { parseTime } from "@/utils/ruoyi";
Vue.use(Vant);
import '@/styles/common.less'// 解决兼容性布局
import './styles/compatibility.less'// flex布局
import '@/assets/icons/index'
import jquery from 'jquery'
Vue.prototype.$ = jquery
Vue.config.productionTip = false
Vue.prototype.parseTime = parseTime
Vue.prototype.$EventBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
