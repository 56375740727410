// 本地存储相关
// 将token的操作进行封装，避免后面出错
const key = 'my-token'

export const setToken = (newToken) => {
  localStorage.setItem(key, newToken)
}

export const getToken = () => {
  return localStorage.getItem(key)
}

export const delToken = () => {
  localStorage.removeItem(key)
}
